/* ==========================================================================
   Base styling
   ========================================================================== */





body:not(.section-front-page):not(.section-events){

    #content .documentFirstHeading{
        display: none;
    }
}

h1,h2,h3,h4,h5,h6{
    margin-top: 40px;
    margin-bottom: 10px;
}
p,ul,ol,span{
    font-weight: 400;
    margin-bottom: 5px;
}

a:hover{
    color: @color-link-hover;
    text-decoration: underline;
}

.configlets{

    [class^="icon-"]:before,
    [class*=" icon-"]:before{
        color: @tomato;
    }
}

#portlet-prefs ul ul li{
    a:before{
        color: @teal;
    }

    a:hover,
    a:focus{
        font-weight: 900;
    }
}

/* Blue Wrap */

#blue {
    background: #3498db;
    padding-top: 35px;
    padding-bottom: 35px;
    margin-bottom: 20px;
}

#blue .documentFirstHeading {
    // font-family: @font-lato;
    color: white;
    font-weight: 400;
    border: 0;
    margin: 0;
    padding: 2% 5%;
}

#blue p {
    color: white;
}

#headerwrap{
    margin-bottom: 3%;
}


/* calender event */
.vevent div.cal_date{
    border: 0 !important;
    border-radius: 0 !important;
    box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16),0 2px 10px 0 rgba(0,0,0,0.12) !important;
    padding: 0 !important;
    margin-top: 2.5em !important;
}

.vevent span.cal_wkday{
    font-size: 0.9em !important;
    border-bottom: 0 !important;
}

.vevent span.cal_month{
    display: block !important;
    font-size: 1em !important;
}
