/* ==========================================================================
   FOOTER
   ========================================================================== */





#portal-footer-wrapper{

    background-color: @bg-footer;;

    .portletWrapper {
      display: block;
    }
    .portlet {

        background: transparent;
        border: none;
        box-shadow: none;
        margin-bottom: 0;

        .portletContent{
            text-align: center;
            border-top: 0;

            a{
                color: @blue;
            }

            >*{
                padding: 0;
            }

            >ul>li a{
                padding-bottom: 15px;
            }

            >ul>li a:before{
                content: "";
            }
        }
    }

}