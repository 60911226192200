a#portal-logo {
	height: 60px;
	overflow: hidden;
	padding-top: 10px;
	margin-left: 10px;
}

a#portal-logo img{
	height: auto;
	width:  auto;
	max-width: 100%;
	max-height: 100%;
}
 
#content-header  {
	background: white;
	border-bottom:  1px solid #CCC;
	box-shadow: 0 0 12px rgba(0,0,0, 0.3);
	position: fixed;
	left: 0;
	right: 0;
	top:  0;
	z-index: 4;
	min-height: 70px;
 
}

div.outer-wrapper {
	padding-top: 116px;
}

.mosaic-grid div.outer-wrapper {
	padding-top: 70px;
}

#content-header div.container.flex {
	display: flex;
	margin: auto;
}

div#portal-globalnav-wrapper {
	margin-left: auto;
	padding-left: 100px;
}


.plone-collapse.plone-navbar-collapse.collapse.in {
	position: fixed;
	left:  0;
	right:  0;
	text-align: center;
	background: white;
	box-shadow: 0 7px 7px rgba(0,0,0, 0.3);
	border-top:  1px solid;
}



div#portal-globalnav-wrapper a {
	color:  #777;
	/* background: inherit; */
}

div#portal-globalnav-wrapper a:hover {
	color:  inherit;
}

div#portal-globalnav-wrapper ul.submenu {
	background: white; ;
	box-shadow: 3px 3px 7px rgba(0,0,0, 0.3);
}



#portal-globalnav-wrapper div.plone-navbar-header {
	margin: 6px 10px;
}

button.plone-navbar-toggle {
	background: #555 !important;
	font-size: 140%
}



 
