/* ==========================================================================
   INPUTS
   ========================================================================== */



input[type='text']:not(.searchField),
input[type='password'],
textarea,
input[type='number']{

    height: 40px;
    background-color: @gray-lightest;
    color: contrast(@gray-lightest);
    box-shadow: none;
    font-size: 18px;
    border-radius: 0;
    border: 0;
    outline: 1px solid @gray-lighter;

    &:hover{
        background-color: @gray-lightest;
    }

    &:focus{
        background-color: @white;
        color: @gray-darkest;
        outline: 2px solid @gray-light;
        box-shadow: none;

    }
}

.field{

    &.error{

        background-color: transparent;
        border: 0;
        box-shadow: none;

        div:not([class="formHelp"]){
            color: @red;
        }

        label{
            color: @red;
        }

        input{
            background-color: fade(@error, 30%);

            &:focus{
                background-color: @white;
            }
        }
    }
}

.pat-resourceregistry .input-sm{

    border-radius: 0 !important;
    background-color: @gray-lightest !important;
    color: contrast(@gray-lightest) !important;
    outline: 1px solid @gray-lighter !important;
    border: 0 !important;
    box-shadow: none !important;
    margin-bottom: 4px !important;

    &:focus{

        background-color: @white !important;
        color: @gray-darkest !important;
        outline: 2px solid @gray-light !important;
        box-shadow: none !important;
        font-weight: 500;
    }
}

select {

    display: block;
    width: 100%;
    padding: 6px 12px;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.42857143;
    background-color: #fff;
    background-image: none;
    border: 1px solid @blue;
    border-radius: 0;
}

.select2-container{

    border: 1px solid @blue;
    border-radius: 0;
    background: @gray-lighter;
    outline: none;

}

.label{
    color: @gray-darkest;
}
