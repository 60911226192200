/* ==========================================================================
   SEARCH PAGE and LIVE SEARCH
   ========================================================================== */




.nav {

    #portal-searchbox{
    float: none;
    max-height: 45px;
}

.LSBox{

    .searchField{
         background: transparent;
         outline: 0;
         border: 0;
         box-shadow: none;
         border-bottom: 2px solid @gray-light;
         border-radius: 0;
         color: @white-faded;
         font-weight: 700;

         &:focus{
            background-color: rgba(0, 0, 0, 0.5);
            border-bottom: 2px solid @border-live-search !important;
         }
    }

    label{
        color: @white-faded;
    }
    .search-icon{

        padding: 10px 12px;
        margin-top: -2px;
        position: relative;
        left: -35px;
        background: transparent;
        border: 0;

        i{
            color: @color-icon;
        }

        &:hover,
        &:focus{

            border: 0;
            outline: none;
            box-shadow: none;

            i{
                color: @white;
            }
        }
    }

    .searchSection{

        position: relative;
        // right: 200px;
        visibility: hidden;
        top: -20px;
    }
    .show-searchSection{
        visibility: visible !important;
        top: 0 !important;
    }

    .livesearch-results{
        border: 0px !important;
        box-shadow: none !important;

        li{
            text-transform: none;
        }

        h4 a{
            font-weight: 400;
            font-family: @font-lato;
        }

        p{
            margin: 0;
        }
    }

    input[type="text"]:focus{
        border: 0;
        box-shadow: none;
    }
}

}

/* Mobile live search
   ========================================================================== */


.mobile-live-search{


    #portal-searchbox{
    float: none;
    position: absolute;
    top: 25px;
    right: 70px;
    margin-top: -5px;
    padding-top: 0;

    .pat-livesearch{
        min-width: 100px;
    }
}

.LSBox{

    .searchField{
         background: transparent;
         outline: 0;
         border: 0;
         box-shadow: none;
         border-bottom: 1px solid @gray-light;
         border-radius: 0;
         color: @white;
         font-weight: 700;
         width: 100px;
         transition-property: width;
        transition-duration: 200ms;
        transition-timing-function: ease;

         &:focus{
            background-color: @bg-navbar-inverse;
            border-bottom: none !important;
            width: 100vw;
            position: absolute;
            top: 0;
            right: -70px;
            margin-top: -20px;
            padding: 41px 20px;
         }
    }

    label{
        color: @gray;
    }


    .livesearch-results{
        border: 0px !important;
        box-shadow: none !important;
        width: 100vw !important;
        margin-top: -24px;

        li{
            &.no-search{
                display: none !important;
            }
            text-transform: none;
        }

        h4 a{
            font-weight: 400;
            font-family: @font-lato;
        }

        p{
            margin: 0;
        }
    }

    input[type="text"]:focus{
        border: 0;
        box-shadow: none;
    }
}
}

.searchPage .actionMenu dd{
    z-index: 2;
}

input[type="submit"].searchPage{
    padding: 8px;
    font-size: 17px;
}