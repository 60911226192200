/* ==========================================================================
   PORTLETS
   ========================================================================== */





/* DEFAULT STYLING FOR ALL PORTLETS
   ========================================================================== */

aside {

    section.portlet {

        margin-bottom: 20px;
        border: 1px solid @gray-light;
        box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16),0 2px 10px 0 rgba(0,0,0,0.12);
        border-radius: 0;

        /* portlet header */
        .portletHeader{

            color: @color-portlet-header;
            background-color: @bg-portlet-header;
            text-align: center;
            text-transform: uppercase;
            font-weight: 500;
            text-shadow: none;
            font-family: @font-raleway;

            a{
                color: @color-portlet-header;

                &:hover,
                &:focus{

                    color: @gray;
                    text-decoration: none;
                }
            }
        }

        /* portlet item and content */
        .portletItem{

            a{
                color: @color-portlet-link;

                &:before{

                    content: "/2014";
                    position: absolute;
                    left: 12px;
                }
            }
        }
    }
}


/* News, RSS, Events, Recent portlets
   ========================================================================== */

aside{

    .portletNews,
    .portletRss,
    .portletEvents,
    .portletRecent{

        section.portletContent{

            ul{

                li{

                    a{
                        color: @color-portlet-link;

                        &:before{

                            content: "—";
                            position: absolute;
                            left: 12px;
                            font-size: inherit;
                            margin-top: 0;
                            color: @color-portlet-link;
                        }

                        &:hover,
                        &:focus{

                            color: darken(@color-portlet-link, 10%);
                            text-decoration: underline;
                        }
                    }
                }
            }

        }
    }

}

/* NavigationTree Portlet
   ========================================================================== */

.portletNavigationTree{

    border: 0 !important;
    box-shadow: none !important;

    .portletContent{

        background-color: transparent;

        ul.navTree{

            list-style: none;

            li.navTreeItem{

                a{
                    background-color: @white-faded;
                    font-size: 16px;
                    text-transform: uppercase;
                    color: @color-portlet-link;

                    &:hover,
                    &:focus{

                        color: darken(@color-portlet-link, 10%);
                        font-weight: 700;
                    }
                }
            }
        }
    }

    @media (max-width:767px) {
        visibility: hidden;
        height: 0;

    }
}




/* Search Portlet
   ========================================================================== */

.portletSearch{

    input[type="text"]{
        height: 40px;
        background-color: @gray-lightest;
        color: contrast(@gray-lightest);
        box-shadow: none;
        font-size: 18px;
        border-radius: 0;
        border: 0;
        outline: 1px solid @gray-lighter;

        &:hover{
            background-color: @gray-lightest;
        }

        &:focus{
            background-color: @white;
            color: @gray-darkest;
            outline: 2px solid @gray-light;
            box-shadow: none;

        }
    }

    .searchField.portlet-search-gadget+.searchButton{
        float: none;
        margin-top: 10px;
    }

}


/* Control Panel Portlets
   ========================================================================== */

.portletSiteSetup {

    // nav ul li{
    //     border-bottom: none ;
    // }

    // a {
    //     font-size: 16px;
    //     text-decoration: none !important;
    // }
}

.managedPortletActions{
    margin-top: -15px;
}

.portlet.portletCalendar table{

    width: 99.5%;
    margin-left: 1px;

    td>*{
        margin: 5px 0;
    }
}