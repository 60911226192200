
@main-maree-trad-color: #FE2F2E;

.mosaic-grid div.outer-wrapper,
div.outer-wrapper {
	overflow-x:hidden;
	padding-top: 5px;
}

.mosaic-grid  #content {
 	margin-bottom: 0;
}

.mosaic-grid-row-fullwidth {
  .container;
}

@media (max-width: 998px) {
  #portal-column-two,
  #sidebar{
    width: 100%;
  }
}

#sidebar img {
  max-width: 100%;
}

.arrow_down {
	width: 134px;
	height: 50px;
	border-left: 67px solid transparent;
	border-right: 67px solid transparent;
	border-top: 50px solid @plone-body-bg;
	margin-bottom: 15px;
}

.plone-link { color: @plone-link-color !important}
.plone-link-2 { color: @plone-link-secondary !important}
.plone-link-3 { color: @plone-link-tertiary !important }
.plone-link-opposite { color: @plone-link-opposite  !important}

.plone-background { background: @plone-link-color  !important}
.plone-background-2 { background: @plone-link-secondary  !important}
.plone-background-3 { background: @plone-link-tertiary  !important}
.plone-background-opposite { background: @plone-link-opposite  !important}


.black-background { background-color: #000  !important}
.white-background { background-color: #FFF  !important}
.grey-background { background-color: #DDD  !important}

.black { color: #000  !important}
.white { color: #FFF  !important}
.grey { color: #DDD  !important}

:root {
  --plone-link-color : @plone-link-color ;
}

:root {
  --plone-link-secondary : @plone-link-secondary ;
}

:root {
  --plone-link-color : @plone-link-color ;
}

:root {
  --plone-link-tertiary : @plone-link-tertiary ;
}

:root {
  --plone-link-opposite : @plone-link-opposite ;
}

/*
 * Author: Carlos Alvarez
 * URL: http://alvarez.is
 *
 * Project Name: SPOT - Free Bootstrap 3 Theme
 * Version: 1.0
 * URL: http://blacktie.co
 */


/* OVERALL ASPECT */

body {
    background-color: #f2f2f2;
    // font-family: "Exo";
    // font-family: 'Cormorant Garamond', serif;
    font-familiy: "Verdana";
    font-weight: 300;
    font-size: 16px;
    color: #555;
    -webkit-font-smoothing: antialiased;
    -webkit-overflow-scrolling: touch;

    margin-top: 70px;
}

/* Titles */
h1, h2, h3, h4, h5, h6 {
    font-family: "Raleway";
    font-weight: 500;
    color: #333;
}


/* Paragraph & Typographic */
p {
    line-height: 28px;
    margin-bottom: 25px;
}
table {
    background-color: transparent;
    color: inherit;
}

.noborder {
    border-width: 0px;
    border-color: transparent !important;
    border: none;
    tbody, tr, td, th {
        border: none;
    }
    td {
        padding: 0px 3px 0px 3px;
    }
}

.mce-content-body .noborder {
    border-width: 1px;
    border-color: red !important;
    border: 1px dotted red;
    tbody, tr, td, th {
        border-width: 1px;
        border-color: red !important;
        border: 1px dotted red;
    }
    td {
        padding: 0px 3px 0px 3px;
    }
}

.centered {
    text-align: center;
}

/* Links */
a {
    color:  @plone-link-color;
    word-wrap: break-word;

    -webkit-transition: color 0.1s ease-in, background 0.1s ease-in;
    -moz-transition: color 0.1s ease-in, background 0.1s ease-in;
    -ms-transition: color 0.1s ease-in, background 0.1s ease-in;
    -o-transition: color 0.1s ease-in, background 0.1s ease-in;
    transition: color 0.1s ease-in, background 0.1s ease-in;
}

a:hover,
a:focus {
    color: #7b7b7b;
    text-decoration: none;
    outline: 0;
}

a:before,
a:after {
    -webkit-transition: color 0.1s ease-in, background 0.1s ease-in;
    -moz-transition: color 0.1s ease-in, background 0.1s ease-in;
    -ms-transition: color 0.1s ease-in, background 0.1s ease-in;
    -o-transition: color 0.1s ease-in, background 0.1s ease-in;
    transition: color 0.1s ease-in, background 0.1s ease-in;
}

 hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #ccc;
    margin: 1em 0;
    padding: 0;
}


.navbar {
	font-weight: 800;
	font-size: 14px;
	padding-top: 15px;
	padding-bottom: 15px;
}

.navbar-inverse {
	background: #2d2d2d;
	border-color: #2d2d2d;
}

.navbar-nav > li > a:hover,
.navbar-nav > .active > a:hover,
.navbar-nav > .active > ul > li > a:hover,
.plone-navbar-nav li .submenu a:hover {
	// background: darken(@white, 15%);
	background: @white;
	border-bottom: solid @main-maree-trad-color;
}
.plone-navbar-nav li .submenu a {
	line-height: 10px;
}
.navbar-inverse .navbar-nav > .active > a,
.navbar-nav > .active > a {
	// background: lighten(@plone-link-color, 0%);
	// background: darken(@white, 3%);
	// background: lighten(@main-maree-trad-color, 35%);
	background: @white;
	border-top: solid @main-maree-trad-color;
}

.plone-navbar-nav li .submenu li {
	position: relative;
	float: right;
	background-color: white;
	&:hover ul {
		left: 0%;
		right: 100%;
	}
}
/*
.plone-navbar-nav li .submenu li:hover ul {
	left: 0%;
	right: 200%;
}
*/

.navbar-inverse .navbar-brand {
	color: #999999;
	font-weight: bolder;
	font-size: 22px;
	letter-spacing: 1px;
}

.navbar-inverse .navbar-brand i {
	color: lighten(@plone-link-color, 40%);
	font-size: 22px;
	letter-spacing: 1px;
}

/* Breadcrumbs */
// @white defined in partials/variables.less
.plone-breadcrumb {
    margin-top: 5px;
    margin-bottom: 0;
    background-color: darken(@white, 3%);
    color: darken(@white, 70%);

    ol {
        font-size: 16px;
        a {
            color: darken(@white, 85%);
            // background-color: darken(@white, 25%);
        	&:hover {
        		border-bottom: solid @main-maree-trad-color;
        	}
        }
    }
    ol>li+li{
	    &:before,
	    &:after{
	        content: "\232A";
	        position: absolute;
	        padding: 0 5px;
	        border: 0 !important;
	        left: -21px;
	        top: 0px;
	        color: black;
	    }
	}
}

#maree-trad-header {
	width: 50%;
	img {
		min-width: 40px;
	}
	#header-text {
		padding-top: 5px;
		.title {
			color: red;
			font-size: 25px;
			font-weight: bold;
		}
		.text {
			font-style: italic;
			font-size: 14px;
			color: black;
		}
	}
}

@media (max-width: @plone-screen-md-min) {
	#maree-trad-header {
		width: 20%;
		#header-text {
			display: none;
		}
		img {
			min-width: 50px;
		}
	}
	div#portal-globalnav-wrapper {
		margin-left: 15px;
		padding-left: 0px;
	}
}
