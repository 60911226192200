/* ==========================================================================
   NAVABR
   ========================================================================== */




.navbar-fixed-top{

    z-index: 4;
    text-transform: uppercase;

    #portal-logo{
        margin-top: 5px;
        margin-left: 5px;

        img {
            max-width: 150px;
            opacity: 0.7;
        }
    }

    a{
        font-weight: 800;
    }
}

nav.navbar {

    .plone-toolbar-left & {
        padding-left: 60px;
    }

    .plone-toolbar-left-expanded & {
        padding-left: 120px;
    }
}
