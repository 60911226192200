/* ==========================================================================
   BREADCRUMBS
   ========================================================================== */





body.section-front-page{

    .plone-breadcrumb{
        display: none;
    }
}

.plone-breadcrumb{

    margin-top: 10px;
    margin-bottom: 0;
    background-color: darken(@blue, 10%);
    color: darken(@white, 15%);

    ol {

        font-size: 16px;

        a{
            color: darken(@white, 20%);
        }
    }
}

.plone-breadcrumb ol>li+li{

    &:before,
    &:after{
        content: "\232A";
        position: absolute;
        padding: 0 5px;
        border: 0 !important;
        left: -21px;
        top: 0px;
        padding: 0 !important;
    }
}

