/* ==========================================================================
   VARIABLES
   ========================================================================== */




/* Colors */
@white:         #ffffff;
@white-faded:   #f2f2f2;
@gray-darkest:  #222222;
@gray-darker:   #2d2d2d;
@gray-dark:     #333333;
@gray:          #7b7b7b;
@gray-light:    #999999;
@gray-lighter:  #cccccc;
@gray-lightest: #e0e0e0;
@gray-faded:    #ebebeb;
@orange:        #f85c37;
@red:           #FB3640;
@tomato:        #ff7878;
@blue:          #3498db;
@teal:          #1abc9c;
@yellow:        #E2EF70;

/* Background colors */
@bg-body:           @white-faded;
@bg-navbar-inverse: @gray-darker;
@bg-navbar-link:    @tomato;
@bg-footer:         @gray-darker;
@bg-portlet-header: @gray-faded;

/* Font colors */
@color-body:            @gray;
@color-headings:        @gray-dark;
@color-link:            @orange;
@color-link-hover:      @gray-darker;
@color-icon:            @tomato;
@color-portlet-header:  darken(@gray-darkest, 0%);
@color-portlet-link:    @blue;

/* border colors */
@border-navbar-inverse: @gray-darker;
@border-live-search:    @tomato;

@color-checkbox:        @teal;

/* alert */
@info:              fade(@blue, 50%);
@warning:           fade(@yellow, 50%);
@error:             fade(@red, 50%);
@success:           fade(@teal, 50%);

/* buttons */
@button-context:           @teal;
@button-standalone:        @blue;
@button-destructive:       darken(@tomato,10%);
@button-default:           @gray-lightest;

/* Font family */
@font-lato:         "Lato";
@font-raleway:      "Raleway";
