
/* Ces imports dans un fichier separe car bug dans eclipse ;-( */

@import url(https://fonts.googleapis.com/css?family=Lora:400,400italic,700,700italic);
@import url(https://fonts.googleapis.com/css?family=Exo:500,600,700);
@import url(https://fonts.googleapis.com/css?family=Courgette);

@import url('https://fonts.googleapis.com/css?family=Cormorant+Garamond');
// font-family: 'Cormorant Garamond', serif;


